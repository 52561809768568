<template>
  <b-card>
    <validation-observer ref="resetPassword">
    <!-- form -->
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col cols="6">
          <b-form-group
            label="Password"
            label-for="a-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="old-password"
              rules="required|min:5"
            >

            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old-password"
                :type="passwordFieldTypeOld"
                :state="errors.length > 0 ? false:null"
                placeholder="Old Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>


        
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <!-- old password -->
        <b-col cols="6">
          <b-form-group
            label="New Password"
            label-for="newPassword"
          >
            <validation-provider
              #default="{ errors }"
              name="newPassword"
              vid="new-password"
              rules="required|min:5"
            >

            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                name="new-password"
                :type="passwordFieldTypeNew"
                :state="errors.length > 0 ? false:null"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>


        
        <!--/ new password -->

        <!-- retype password -->

        <b-col cols="6">
          <b-form-group
            label="Retype Password"
            label-for="Retype New Password"
          >
            <validation-provider
              #default="{ errors }"
              name="retype-new-password"
              vid="RetypePassword"
              rules="required|min:5|confirmed:new-password"
            >

            <b-input-group class="input-group-merge">
              <b-form-input
                id="retype-new-password"
                v-model="RetypePassword"
                name="retype-password"
                :type="passwordFieldTypeRetype"
                :state="errors.length > 0 ? false:null"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
          v-if="!loading"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="validationForm"
          >
            
            Save changes
          </b-button>
          <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-if="loading"
                variant="primary"
                disabled
                block
                class="mt-1 mr-1"
              >
                <b-spinner
                  small
                  type="grow"
                />
                Loading...
              </b-button>
          
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {required, min, confirmed,} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BSpinner
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      loading: false,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    validationForm() {
      this.$refs.resetPassword.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.resetPassword()
        }
      })
    },

    resetPassword(){
 
      this.loading = true;
      let data = {
        old_password: this.passwordValueOld, //userEmail
        password: this.newPasswordValue,
        password_confirmation: this.RetypePassword,
      }
        axios.post("/resetPassword",data)
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'success',
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            
            this.passwordValueOld = this.newPasswordValue = this.RetypePassword = ''
          }else if(responseCode == 103 || responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          // router.push('/');
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }

          let message = Error

          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    }
  },
}
</script>
