<template>
    
  <b-card>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
    <validation-observer ref="simpleRules">
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
       
        <!-- name -->
        <b-col md="6">
          <b-form-group
            label="Name"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                v-model="data.name"
                :state="errors.length > 0 ? false:null"
                placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>


        <!-- email -->
        <b-col md="6">
          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                v-model="data.email"
                :state="errors.length > 0 ? false:null"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- phone -->
        <b-col md="6">
          <b-form-group
            label="Phone"
            label-for="phone"
          >
            <validation-provider
              #default="{ errors }"
              name="Phone"
              rules="required|integer|digits:12"
            >
              <b-form-input
                v-model="data.phone"
                :state="errors.length > 0 ? false:null"
                placeholder="Phone"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            variant="primary"
            class="mt-2 mr-1"
             @click.prevent="validationForm"
          >
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
  </b-overlay>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { BOverlay } from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      data: [],
      isLoading: true,
    }
  },

  methods: {
     validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.sendData()
        }
      })
    },
    getData(){
      this.isLoading = true;
        axios.get("/adminsInfo")
        .then(response => {
          this.isLoading = false
          this.data = response.data.data
          this.id = 
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'successfully',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'success',
              },
            })
       
          
        })
    },
    sendData(){
 
      this.isLoading = true;
      let data = {
        name:     this.data.name,
        phone:    this.data.phone,
        email:    this.data.email,
      }
        axios.put(`/admins/${this.data.id}`, data)
        .then(response => {
          this.isLoading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'success',
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            
            this.passwordValueOld = this.newPasswordValue = this.RetypePassword = ''
          }else if(responseCode == 103){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          // router.push('/');
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }

          let message = Error

          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    }
  },
  mounted() {
    this.getData()
  },

}
</script>
